import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { RouteObserverService } from '@/services/route-observer.service';
import { Agence, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardComponent implements OnChanges {
  @Input() agence?: Agence;
  @Input() team?: Negociateur;
  @Input() teamAll?: any;
  @Input() showSite = false;

  public MainRoutes = MainRoutes;
  public isAgence = false;
  public isGroupe = false;
  public contact: string;
  public phone = '';

  constructor(routeObserver: RouteObserverService) {
    this.isGroupe = routeObserver.currentRoute.url.includes(MainRoutes.Groupes);
    this.isAgence = routeObserver.currentRoute.url.startsWith('/agence-');
  }

  ngOnChanges(): void {
    this.contact = '';

    if (this.teamAll) {
      this.phone = this.teamAll.telephone || this.teamAll.agence.telephone || '';
    } else if (this.team || this.agence) {
      this.phone = this.team?.telephone || this.agence?.telephone || '';
    }

    if (this.teamAll) {
      this.contact = this.teamAll.status ? `${MainRoutes.Effectifs}/${this.teamAll.website_slug}` : `/${this.teamAll.agence.slug}`;
    } else if (this.agence && this.isGroupe) {
      this.contact = this.team?.status ? `${MainRoutes.Effectifs}/${this.team.website_slug}` : `${MainRoutes.Groupes}/${this.agence.slug}`;
      this.contact += `-${this.agence.id}`;
    } else if (this.team?.status) {
      this.contact = `${MainRoutes.Effectifs}/${this.team?.website_slug}`;
    } else if (this.agence) {
      this.contact = `/${this.agence?.slug}`;
    }

    if (this.contact) {
      this.contact += '/contact';
    }
  }
}
