<div class="contact-content display-text-16px">
  <app-button-telephone
    aspect="arrow-blue"
    [telephone]="phone"
    size="large"
  />

  @if (contact) {
    <app-button
      [link]="contact"
      aspect="arrow-blue"
      text="contact"
      size="large"
    />
  }

  @if (team?.status && showSite) {
    <app-button
      [text]="(team.civilite === 'Monsieur') ? 'advisor_male' : 'advisor_female'"
      [link]="MainRoutes.Effectifs + '/' + (team.website_slug ?? team.id)"
      [fragment]="isAgence ? 'agence' : undefined"
      size="large"
    />
  }
</div>
